<template>
  <div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" v-if="!loading" :loading="loading">
          <a-form layout='vertical' :form="form" @submit="submitForm">
            <a-form-item>
              <div class="ant-page-header-heading-title">Basic Info</div>

            </a-form-item>
            <a-form-item label='Video Event Title'>
              <a-input
                  placeholder="Input a descriptive event name"
                  v-decorator="[

              'title',
                        { rules: [{ required: true, message: 'Event Title is required' }],
                        initialValue:selectedItem.title
              }
            ]"
              />
            </a-form-item>
            <a-form-item label='Description'>
              <a-input
                  :auto-size="{ minRows: 4, maxRows: 8 }"
                  type='textarea'
                  :maxLength="1500"
                  placeholder="Write short description here"
                  v-decorator="['description',{
                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',
              }]"
              />
            </a-form-item>
            <a-row :gutter="24" type="flex">
              <a-col :span="12" >
            <a-form-item label='Collection'>
              <a-select
                  show-search
                  placeholder="Select a collection"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  :loading="loadingCategories"
                  v-decorator="[
          'collectionId',
          { rules: [{ required: true, message: 'Please select a collection!' }],
           initialValue:selectedItem.collectionId
           },
        ]"
              >
                <a-select-option :value="cat._id" v-for="(cat,index) in getAllCategories" :key="cat._id">
                                <span style="margin-right: 5px;align-items: center" role="img" >
                <a-avatar shape="square" :src="url+cat.image"></a-avatar>
      </span>
                  {{cat.title}}
                </a-select-option>
              </a-select>
            </a-form-item>
              </a-col>
                <a-col :span="12" >
                  <a-form-item label="Date">
                    <a-date-picker style="width: 100%" v-decorator="[
          'date',
          { rules: [{ required: true, message: 'Please select a date' }],
           initialValue:moment(selectedItem.date)
           },]"/>

                  </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="24" type="flex">
              <a-col :span="12" >
                <a-form-item label='Street Address'>
                  <a-input
                      placeholder="Input a descriptive address"
                      v-decorator="[

              'streetAddress',
                        { rules: [{ required: true, message: 'Street Address is required' }] ,
                        initialValue:selectedItem&&selectedItem.address?selectedItem.address.streetAddress:'',
              }
            ]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12" >
                <a-form-item label='Post Code'>
                  <a-select
                      show-search
                      placeholder="Input Post Code"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="fetching ? undefined : null"
                      @search="fetchPostCode"
                      @change="handlePostCodeChange"
                      v-decorator="[
              'post_code_with_address',
                        { rules: [{ required: true, message: 'Post Code is required' }],
                       initialValue:selectedItem&&post_code_with_address?post_code_with_address:undefined
                         },
            ]"
                  >
<!--                    <a-select-option value="">-->
<!--                      Input Post Code-->
                      <a-spin v-if="fetching" slot="notFoundContent" size="small" />
<!--                    </a-select-option>-->
                    <a-select-option v-for="d in data" :key="d.value">
                      {{ d.text }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-form-item label='Tags'>
              <a-select dropdownClassName="tags-dropdown" mode="tags" style="width: 100%" placeholder="Type in your tag name and hit Enter"
                        v-decorator="[
          'tags',
          {
                       initialValue:selectedItem.tags

          }
        ]"
              >

              </a-select>
            </a-form-item>


            <a-form-item  label='Related Event'>
              <div id="events" >
                <a-select
                    show-search
                    placeholder="Select an event"
                    option-filter-prop="children"
                    :show-arrow="false"
                    :filter-option="false"
                    allowClear
                    showSearch
                    @search="allEvents"
                    :loading="loadingEvents"
                    v-decorator="[
          'event',
          {
           initialValue:selectedItem&&selectedItem.event?selectedItem.event:undefined
           },
        ]"
                >
<!--                  <a-select-option value="">-->
<!--                    Select an event-->
                    <a-spin v-if="loadingEvents" slot="notFoundContent" size="small" />

<!--                  </a-select-option>-->

                  <a-select-option :value="item._id" v-for="(item,index) in events" :key="item._id">
                    <h6 class="avatar-info">
                      <a-spin v-if="index==0&&loadingEvents" size="small" />
                      <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600">#{{item.eventNumber}}</span>
                      {{item.title}} <a-tag v-if="item" class="tag-status ant-tag-primary" :class="{'ant-tag-success':item.status=='published'||item.status=='Published','ant-tag-danger':item.status=='deleted'||item.status=='Deleted','ant-tag-warning':item.status=='Inactive'||item.status=='inactive'}" style="margin-left: 10px">
                      {{ item.status|capitalizeFirstLetter }}
                    </a-tag>
                    </h6>
<!--                    <div style="display: flex">-->
<!--                                <span style="margin:0 5px 0 0">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>-->
<!--                </span>-->
<!--                      <div >-->
<!--                        <p>{{item.locationType=='physical'?(item.address.post_code + ', ' + item.address.district + ', ' + item.address.country):(item.locationType=='online'?'Online':'Not Announced')-->
<!--                          }}</p>-->

<!--                      </div>-->

<!--                    </div>-->
                  </a-select-option>
                </a-select>
              </div>
            </a-form-item>

            <a-form-item>
              <div class="ant-page-header-heading-title">Video Information</div>

            </a-form-item>

            <a-form-item style="margin-bottom: 8px">
              <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px;">Video</div>

              <a-radio-group button-style="solid" class="mb-15" v-decorator="[
              `video_type`,{
              initialValue:selectedItem?selectedItem.videoDetail.type:'video'
              }]">
              <a-radio-button value="video">
                Upload Video
              </a-radio-button>
              <a-radio-button value="video_source">
                Add Video Source
              </a-radio-button>
            </a-radio-group>
            </a-form-item>
            <a-form-item v-if="form.getFieldValue(`video_type`)=='video'">
              <a-upload
                  list-type="picture-card"
                  :default-file-list="fileList"
                  :before-upload="handleChange"
                  :multiple="false"
                  :remove="handleRemove"
                  v-decorator="[
              `video`,
                        { rules: [{ required: true, message: 'Video is required' }] ,
                                        initialValue:fileList,
                                        }
            ]"
                  accept=".mp4"
              >
                <div v-if="fileList&&fileList.length < 1">
                  <p class="ant-upload-drag-icon" style="margin-bottom: 15px;">
                    <a-icon type="inbox" style="color: #40a9ff;font-size: 40px;"/>
                  </p>
                  <p class="ant-upload-text" style="margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;">
                    Click or drag file to this area to upload
                  </p>
                  <p class="ant-upload-hint" style="color: rgba(0, 0, 0, 0.45);
    font-size: 14px;">
                    Only single file with maximum file size of 15 MB is supported.

                  </p>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
            <a-form-item v-if="form.getFieldValue(`video_type`)=='video_source'" label="External Video Url">
              <a-input
                  placeholder="Input a video source"
                  v-decorator="[
              `sourceUrl`,
                        { rules: [{ required: true, message: 'Video Source is required'}] ,
                        initialValue:selectedItem?selectedItem.videoDetail.sourceUrl:'',
              }
            ]"
              />
              <div v-if="form.getFieldValue('sourceUrl')">
                <iframe width="300" height="200" style="margin-top: 10px"
                        :src="form.getFieldValue('sourceUrl')"
                >
                </iframe>
              </div>
            </a-form-item>
            <a-form-model-item label="Video Source Platform Name"
            >
              <a-input
                  placeholder="Input a video source platform name like Youtube, Vimeo"
                  v-decorator="[
              `sourceName`,
                        {
                        initialValue:selectedItem?selectedItem.videoDetail.sourceName:'',
              }
            ]"

              />

            </a-form-model-item>

            <a-form-model-item label="Video Source Original Url"
            >
              <a-input
                  placeholder="Input a video source url like https://www.youtube.com/watch?v=JGwWNGJdv"
                  v-decorator="[
              `originalSourceUrl`,
                        {
                        initialValue:selectedItem?selectedItem.videoDetail.originalSourceUrl:'',
              }
            ]"

              />

            </a-form-model-item>


            <a-form-item style="margin-bottom: 8px">
              <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px">Thumbnail</div>
            <a-radio-group button-style="solid" class="mb-15" v-decorator="[
              `thumbnail_type`,{
              initialValue:selectedItem?selectedItem.videoDetail.thumbnail.type:'image'
              }]" @change="forceUpdateDom">
              <a-radio-button value="image">
                Upload Thumbnail Image
              </a-radio-button>
              <a-radio-button value="image_source">
                Add Thumbnail Source
              </a-radio-button>
            </a-radio-group>
            </a-form-item>
            <a-form-item v-if="form.getFieldValue(`thumbnail_type`)=='image'" label='Thumbnail Image'>
                <a-upload
                    :remove="handleThumbnailRemove" :before-upload="beforeUpload"
                    :default-file-list="thumbnailFile"
                    list-type="picture"
                    v-decorator="[
              'thumbnail',
                        {
                          rules: [{ required: true, message: 'Thumbnail Image is required' }] ,
                initialValue:thumbnailFile,
              }
            ]"
                    accept=".jpeg,.jpg,.gif,.png"
                >
                  <a-button :disabled="thumbnailFile.length >0">
                    <a-icon type="upload"/>
                    Upload
                  </a-button>
                </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
            <a-form-item v-if="form.getFieldValue(`thumbnail_type`)=='image_source'" label="External Thumbnail Image Url">
              <a-input
                  placeholder="Input an image source"
                  v-decorator="[
              `thumbnailSourceUrl`,
                        { rules: [{ required: true, message: 'Image Source is required'}] ,
                        initialValue:selectedItem?selectedItem.videoDetail.thumbnail.sourceUrl:'',
              }
            ]"
              />
              <div v-if="form.getFieldValue('thumbnailSourceUrl')">
                <img :src="form.getFieldValue('thumbnailSourceUrl')" alt="Invalid Image URL" class="source-preview"/>
              </div>
            </a-form-item>
            <a-form-model-item label="Thumbnail Source Platform Name"
            >
              <a-input
                  placeholder="Input an image source platform name like Eventbrite, Facebook"
                  v-decorator="[
              `thumbnailSourceName`,
                        {
                        initialValue:selectedItem?selectedItem.videoDetail.thumbnail.sourceName:'',
              }
            ]"

              />

            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 24 }" :style="{ textAlign: 'right' }">
              <a-space size="small">
                <a-button type="primary" html-type="submit" :loading="confirmLoading">
                  {{ footerBtnTitle }}
                </a-button>
                <router-link :to="{name:'Video Clips'}">
                <a-button type="danger" :loading="confirmLoading">
                  Cancel
                </a-button>
                </router-link>
              </a-space>
            </a-form-item>

          </a-form>


        </a-card>
        <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
          <a-row :gutter="[24, 24]">
            <a-col :span="24" >
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
            <a-col :span="12" >
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
            <a-col :span="12" >
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
            <a-col :span="24" >
              <a-skeleton active />
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
          </a-row>
        </a-card>

      </a-col>

    </a-row>
  </div>
</template>

<script>

import moment from 'moment';
import debounce from 'lodash/debounce';


export default {
  data() {
    this.lastFetchId = 0;
    this.fetchEvents = debounce(this.fetchEvents, 600);
    this.fetchPostCode = debounce(this.fetchPostCode, 600);

    return {
      moment,
      previewVisible: false,
      loadingEvents:false,
      events:[],
      previewImage: '',
      type:'video',
      loadingCategories:false,
      data: [],
      value: [],
      fetching: false,
      lng:'',
      lat:'',
      district:'',
      country:'',
      ward:'',
      ccg:'',
      post_code_with_address:'',
      is_thumbnail_deleted:false,
      id:'',
      url: process.env.VUE_APP_SERVER_URL,
      loading: false,
      fileList: [],
      thumbnailFile: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      editMode:false

    };
  },
  computed: {
    footerBtnTitle() {
      return this.editMode ? 'Update Video' : 'Create Video'
    },
    getAllCategories(){
      return this.$store.getters.getAllCategories
    },
    selectedItem(){
      return this.$store.getters.selectedPost
    }
  },
  mounted() {
    this.allFetchCategories();
    this.fetchEvents()
    if (this.$route.params.id){
      this.editMode=true
      this.postInfo()
    }
  },
  methods: {
    fetchPostCode(value) {
      if (value){
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.data = [];
        this.fetching = true;
        fetch('https://api.postcodes.io/postcodes/' + value + '/autocomplete')
            .then(response => response.json())
            .then(body => {
              if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
              }
              if (!body.result) {
                this.data = [{text:'No Post Code Found',value:''}];
                this.fetching = false;
                return;
              }
              const data = body.result.map(item => ({
                text: item,
                value: item,
              }));
              this.data = data;
              this.fetching = false;
            });
      }
      else {
        this.lng=this.lat=this.district=this.country=this.ward=this.ccg=this.post_code=''
      }
    },
    handlePostCodeChange(value) {
      fetch('https://api.postcodes.io/postcodes/' + value)
          .then(response => response.json())
          .then(body => {
            this.lng=body.result.longitude
            this.lat=body.result.latitude
            this.country=body.result.country
            this.district=body.result.admin_district
            this.ward=body.result.admin_ward
            this.ccg=body.result.ccg
            this.post_code=value
            const addr=value+', '+this.district+', '+this.country
            this.form.setFieldsValue({
              post_code_with_address: addr,
            });
            Object.assign(this, {
              data: [],
              fetching: false,
            });
          })

    },
    postInfo(){
      this.loading=true
      this.$store.dispatch("FETCH_POST",{id:this.$route.params.id})
          .then((res)=>{
            let data =res.data
            this.id=data._id
            this.lng=data.address.lng
            this.lat=data.address.lat
            this.country=data.address.country
            this.district=data.address.district
            this.ward=data.address.ward
            this.ccg=data.address.ccg
            this.post_code=data.address.post_code
            this.post_code_with_address = this.post_code + ', ' + this.district + ', ' + this.country
            if (data.videoDetail.type=='video'){
              this.fileList= [
                {
                  uid: '-1',
                  name: data.videoDetail.uploadPath,
                  status: 'done',
                  url: this.url+data.videoDetail.uploadPath,
                  thumbnail: this.url+data.videoDetail.uploadPath,
                },
              ]

            }
            if (data.videoDetail.thumbnail&&data.videoDetail.thumbnail.type=='image') {
              this.thumbnailFile = [
                {
                  uid: '-2',
                  name: data.videoDetail.thumbnail.uploadPath,
                  status: 'done',
                  url: this.url + data.videoDetail.thumbnail.uploadPath,
                  thumbnail: this.url + data.videoDetail.thumbnail.uploadPath,
                },
              ]
            }
          })
          .finally(()=>{
            this.loading=false
            this.forceUpdateDom()
          })
    },
    submitForm(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.id
          values.type='video'
          const formData = new FormData();
          formData.append('video', values.video?values.video.file:'');
          formData.append('thumbnail', values.thumbnail?values.thumbnail.file:'');
          formData.append('sourceUrl',values.sourceUrl)
          formData.append('video_type',values.video_type)
          formData.append('thumbnailSourceUrl',values.thumbnailSourceUrl)
          formData.append('thumbnail_type',values.thumbnail_type)
          formData.append('type',values.type)
          formData.append('id',values.id)
          formData.append('title',values.title)
          formData.append('description',values.description)
          formData.append('collectionId',values.collectionId)
          formData.append('event',values.event)
          formData.append('tags',JSON.stringify(values.tags))
          formData.append('streetAddress',values.streetAddress)
          formData.append('date',values.date)
          formData.append('lng',this.lng)
          formData.append('lat',this.lat)
          formData.append('country',this.country)
          formData.append('district',this.district)
          formData.append('ward',this.ward)
          formData.append('ccg',this.ccg)
          formData.append('post_code',this.post_code)
          formData.append('thumbnailSourceName',values.thumbnailSourceName)
          formData.append('sourceName',values.sourceName)
          formData.append('originalSourceUrl',values.originalSourceUrl)
          this.confirmLoading = true
          if (this.editMode){
            formData.append('is_thumbnail_deleted',this.is_thumbnail_deleted)
            this.$store.dispatch("UPDATE_VIDEO_POST", {id:this.id,form:formData})
                .then((res) => {
                  this.$message.success(res.data.message);
                  this.$emit('changeTab','2')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_VIDEO_POST", formData)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$router.push({name:'Edit Video | Publish/Summary',params:{id:res.data.id}})
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },

    beforeUpload(file) {
      if (this.thumbnailFile.length < 1)
        this.thumbnailFile = [...this.thumbnailFile, file];

      return false;
    },
    cancelForm(){
      this.fileList=[];
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
    allFetchCategories(){
      this.loadingCategories=true
      this.$store.dispatch("FETCH_ALL_COLLECTIONS")
          .then(()=>{
            this.loadingCategories=false
          })
    },
    fetchEvents(search){
      this.$store.dispatch("ALL_EVENTS",{params:{results:7,page:1,search:search,select_options:true}})
          .then((res)=>{
            this.events=res.data.results
            this.loadingEvents=false
          })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    allEvents(search) {
      this.loadingEvents=true
      this.fetchEvents(search)
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(file) {
      if (this.fileList.length < 1)
        this.fileList = [...this.fileList, file];

      return false;
    },
    handleRemove(file) {
      this.fileList = [];
    },
    handleThumbnailRemove(file) {
      this.is_thumbnail_deleted=true
      this.thumbnailFile = [];
    },
    forceUpdateDom() {
      setTimeout(()=>{
        this.$forceUpdate()
      },10)
    },

  },
  beforeCreate() {
    if (!this.$route.params.id)
    this.$store.commit('RESET_SELECTED_POST')
  },
};
</script>
<style>
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select{
  width: 50%;
}
.ant-calendar-time-picker-select:last-child{
  display: none;
}
.ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
}
/*#events .ant-select-selection--single {*/
/*    height: 90px;*/
/*}*/
/*#events .ant-select-selection__rendered {*/
  /*line-height: 90px;*/
/*}*/
</style>
