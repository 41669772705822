var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[(!_vm.loading)?_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":true,"loading":_vm.loading}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.submitForm}},[_c('a-form-item',[_c('div',{staticClass:"ant-page-header-heading-title"},[_vm._v("Basic Info")])]),_c('a-form-item',{attrs:{"label":"Video Event Title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([

              'title',
                        { rules: [{ required: true, message: 'Event Title is required' }],
                        initialValue:_vm.selectedItem.title
              }
            ]),expression:"[\n\n              'title',\n                        { rules: [{ required: true, message: 'Event Title is required' }],\n                        initialValue:selectedItem.title\n              }\n            ]"}],attrs:{"placeholder":"Input a descriptive event name"}})],1),_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description',{
                initialValue:Object.keys(_vm.selectedItem).length!=0?_vm.selectedItem.description:'',
              }]),expression:"['description',{\n                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',\n              }]"}],attrs:{"auto-size":{ minRows: 4, maxRows: 8 },"type":"textarea","maxLength":1500,"placeholder":"Write short description here"}})],1),_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Collection"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'collectionId',
          { rules: [{ required: true, message: 'Please select a collection!' }],
           initialValue:_vm.selectedItem.collectionId
           } ]),expression:"[\n          'collectionId',\n          { rules: [{ required: true, message: 'Please select a collection!' }],\n           initialValue:selectedItem.collectionId\n           },\n        ]"}],attrs:{"show-search":"","placeholder":"Select a collection","option-filter-prop":"children","filter-option":_vm.filterOption,"loading":_vm.loadingCategories}},_vm._l((_vm.getAllCategories),function(cat,index){return _c('a-select-option',{key:cat._id,attrs:{"value":cat._id}},[_c('span',{staticStyle:{"margin-right":"5px","align-items":"center"},attrs:{"role":"img"}},[_c('a-avatar',{attrs:{"shape":"square","src":_vm.url+cat.image}})],1),_vm._v(" "+_vm._s(cat.title)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'date',
          { rules: [{ required: true, message: 'Please select a date' }],
           initialValue:_vm.moment(_vm.selectedItem.date)
           } ]),expression:"[\n          'date',\n          { rules: [{ required: true, message: 'Please select a date' }],\n           initialValue:moment(selectedItem.date)\n           },]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Street Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([

              'streetAddress',
                        { rules: [{ required: true, message: 'Street Address is required' }] ,
                        initialValue:_vm.selectedItem&&_vm.selectedItem.address?_vm.selectedItem.address.streetAddress:'',
              }
            ]),expression:"[\n\n              'streetAddress',\n                        { rules: [{ required: true, message: 'Street Address is required' }] ,\n                        initialValue:selectedItem&&selectedItem.address?selectedItem.address.streetAddress:'',\n              }\n            ]"}],attrs:{"placeholder":"Input a descriptive address"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Post Code"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'post_code_with_address',
                        { rules: [{ required: true, message: 'Post Code is required' }],
                       initialValue:_vm.selectedItem&&_vm.post_code_with_address?_vm.post_code_with_address:undefined
                         } ]),expression:"[\n              'post_code_with_address',\n                        { rules: [{ required: true, message: 'Post Code is required' }],\n                       initialValue:selectedItem&&post_code_with_address?post_code_with_address:undefined\n                         },\n            ]"}],attrs:{"show-search":"","placeholder":"Input Post Code","default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":_vm.fetching ? undefined : null},on:{"search":_vm.fetchPostCode,"change":_vm.handlePostCodeChange}},[(_vm.fetching)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.data),function(d){return _c('a-select-option',{key:d.value},[_vm._v(" "+_vm._s(d.text)+" ")])})],2)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Tags"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tags',
          {
                       initialValue:_vm.selectedItem.tags

          }
        ]),expression:"[\n          'tags',\n          {\n                       initialValue:selectedItem.tags\n\n          }\n        ]"}],staticStyle:{"width":"100%"},attrs:{"dropdownClassName":"tags-dropdown","mode":"tags","placeholder":"Type in your tag name and hit Enter"}})],1),_c('a-form-item',{attrs:{"label":"Related Event"}},[_c('div',{attrs:{"id":"events"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'event',
          {
           initialValue:_vm.selectedItem&&_vm.selectedItem.event?_vm.selectedItem.event:undefined
           } ]),expression:"[\n          'event',\n          {\n           initialValue:selectedItem&&selectedItem.event?selectedItem.event:undefined\n           },\n        ]"}],attrs:{"show-search":"","placeholder":"Select an event","option-filter-prop":"children","show-arrow":false,"filter-option":false,"allowClear":"","showSearch":"","loading":_vm.loadingEvents},on:{"search":_vm.allEvents}},[(_vm.loadingEvents)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.events),function(item,index){return _c('a-select-option',{key:item._id,attrs:{"value":item._id}},[_c('h6',{staticClass:"avatar-info"},[(index==0&&_vm.loadingEvents)?_c('a-spin',{attrs:{"size":"small"}}):_vm._e(),_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)","font-weight":"600"}},[_vm._v("#"+_vm._s(item.eventNumber))]),_vm._v(" "+_vm._s(item.title)+" "),(item)?_c('a-tag',{staticClass:"tag-status ant-tag-primary",class:{'ant-tag-success':item.status=='published'||item.status=='Published','ant-tag-danger':item.status=='deleted'||item.status=='Deleted','ant-tag-warning':item.status=='Inactive'||item.status=='inactive'},staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("capitalizeFirstLetter")(item.status))+" ")]):_vm._e()],1)])})],2)],1)]),_c('a-form-item',[_c('div',{staticClass:"ant-page-header-heading-title"},[_vm._v("Video Information")])]),_c('a-form-item',{staticStyle:{"margin-bottom":"8px"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"16px","margin-bottom":"10px"}},[_vm._v("Video")]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "video_type",{
              initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.type:'video'
              }]),expression:"[\n              `video_type`,{\n              initialValue:selectedItem?selectedItem.videoDetail.type:'video'\n              }]"}],staticClass:"mb-15",attrs:{"button-style":"solid"}},[_c('a-radio-button',{attrs:{"value":"video"}},[_vm._v(" Upload Video ")]),_c('a-radio-button',{attrs:{"value":"video_source"}},[_vm._v(" Add Video Source ")])],1)],1),(_vm.form.getFieldValue("video_type")=='video')?_c('a-form-item',[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "video",
                        { rules: [{ required: true, message: 'Video is required' }] ,
                                        initialValue:_vm.fileList,
                                        }
            ]),expression:"[\n              `video`,\n                        { rules: [{ required: true, message: 'Video is required' }] ,\n                                        initialValue:fileList,\n                                        }\n            ]"}],attrs:{"list-type":"picture-card","default-file-list":_vm.fileList,"before-upload":_vm.handleChange,"multiple":false,"remove":_vm.handleRemove,"accept":".mp4"}},[(_vm.fileList&&_vm.fileList.length < 1)?_c('div',[_c('p',{staticClass:"ant-upload-drag-icon",staticStyle:{"margin-bottom":"15px"}},[_c('a-icon',{staticStyle:{"color":"#40a9ff","font-size":"40px"},attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text",staticStyle:{"margin":"0 0 4px","color":"rgba(0, 0, 0, 0.85)","font-size":"16px"}},[_vm._v(" Click or drag file to this area to upload ")]),_c('p',{staticClass:"ant-upload-hint",staticStyle:{"color":"rgba(0, 0, 0, 0.45)","font-size":"14px"}},[_vm._v(" Only single file with maximum file size of 15 MB is supported. ")])]):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1):_vm._e(),(_vm.form.getFieldValue("video_type")=='video_source')?_c('a-form-item',{attrs:{"label":"External Video Url"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "sourceUrl",
                        { rules: [{ required: true, message: 'Video Source is required'}] ,
                        initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.sourceUrl:'',
              }
            ]),expression:"[\n              `sourceUrl`,\n                        { rules: [{ required: true, message: 'Video Source is required'}] ,\n                        initialValue:selectedItem?selectedItem.videoDetail.sourceUrl:'',\n              }\n            ]"}],attrs:{"placeholder":"Input a video source"}}),(_vm.form.getFieldValue('sourceUrl'))?_c('div',[_c('iframe',{staticStyle:{"margin-top":"10px"},attrs:{"width":"300","height":"200","src":_vm.form.getFieldValue('sourceUrl')}})]):_vm._e()],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"Video Source Platform Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "sourceName",
                        {
                        initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.sourceName:'',
              }
            ]),expression:"[\n              `sourceName`,\n                        {\n                        initialValue:selectedItem?selectedItem.videoDetail.sourceName:'',\n              }\n            ]"}],attrs:{"placeholder":"Input a video source platform name like Youtube, Vimeo"}})],1),_c('a-form-model-item',{attrs:{"label":"Video Source Original Url"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "originalSourceUrl",
                        {
                        initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.originalSourceUrl:'',
              }
            ]),expression:"[\n              `originalSourceUrl`,\n                        {\n                        initialValue:selectedItem?selectedItem.videoDetail.originalSourceUrl:'',\n              }\n            ]"}],attrs:{"placeholder":"Input a video source url like https://www.youtube.com/watch?v=JGwWNGJdv"}})],1),_c('a-form-item',{staticStyle:{"margin-bottom":"8px"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"16px","margin-bottom":"10px"}},[_vm._v("Thumbnail")]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "thumbnail_type",{
              initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.thumbnail.type:'image'
              }]),expression:"[\n              `thumbnail_type`,{\n              initialValue:selectedItem?selectedItem.videoDetail.thumbnail.type:'image'\n              }]"}],staticClass:"mb-15",attrs:{"button-style":"solid"},on:{"change":_vm.forceUpdateDom}},[_c('a-radio-button',{attrs:{"value":"image"}},[_vm._v(" Upload Thumbnail Image ")]),_c('a-radio-button',{attrs:{"value":"image_source"}},[_vm._v(" Add Thumbnail Source ")])],1)],1),(_vm.form.getFieldValue("thumbnail_type")=='image')?_c('a-form-item',{attrs:{"label":"Thumbnail Image"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'thumbnail',
                        {
                          rules: [{ required: true, message: 'Thumbnail Image is required' }] ,
                initialValue:_vm.thumbnailFile,
              }
            ]),expression:"[\n              'thumbnail',\n                        {\n                          rules: [{ required: true, message: 'Thumbnail Image is required' }] ,\n                initialValue:thumbnailFile,\n              }\n            ]"}],attrs:{"remove":_vm.handleThumbnailRemove,"before-upload":_vm.beforeUpload,"default-file-list":_vm.thumbnailFile,"list-type":"picture","accept":".jpeg,.jpg,.gif,.png"}},[_c('a-button',{attrs:{"disabled":_vm.thumbnailFile.length >0}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Upload ")],1)],1),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1):_vm._e(),(_vm.form.getFieldValue("thumbnail_type")=='image_source')?_c('a-form-item',{attrs:{"label":"External Thumbnail Image Url"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "thumbnailSourceUrl",
                        { rules: [{ required: true, message: 'Image Source is required'}] ,
                        initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.thumbnail.sourceUrl:'',
              }
            ]),expression:"[\n              `thumbnailSourceUrl`,\n                        { rules: [{ required: true, message: 'Image Source is required'}] ,\n                        initialValue:selectedItem?selectedItem.videoDetail.thumbnail.sourceUrl:'',\n              }\n            ]"}],attrs:{"placeholder":"Input an image source"}}),(_vm.form.getFieldValue('thumbnailSourceUrl'))?_c('div',[_c('img',{staticClass:"source-preview",attrs:{"src":_vm.form.getFieldValue('thumbnailSourceUrl'),"alt":"Invalid Image URL"}})]):_vm._e()],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"Thumbnail Source Platform Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "thumbnailSourceName",
                        {
                        initialValue:_vm.selectedItem?_vm.selectedItem.videoDetail.thumbnail.sourceName:'',
              }
            ]),expression:"[\n              `thumbnailSourceName`,\n                        {\n                        initialValue:selectedItem?selectedItem.videoDetail.thumbnail.sourceName:'',\n              }\n            ]"}],attrs:{"placeholder":"Input an image source platform name like Eventbrite, Facebook"}})],1),_c('a-form-item',{style:({ textAlign: 'right' }),attrs:{"wrapper-col":{ span: 24 }}},[_c('a-space',{attrs:{"size":"small"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.confirmLoading}},[_vm._v(" "+_vm._s(_vm.footerBtnTitle)+" ")]),_c('router-link',{attrs:{"to":{name:'Video Clips'}}},[_c('a-button',{attrs:{"type":"danger","loading":_vm.confirmLoading}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1):_c('a-card',{staticClass:"header-solid h-full",staticStyle:{"min-height":"400px"},attrs:{"bordered":true}},[_c('a-row',{attrs:{"gutter":[24, 24]}},[_c('a-col',{attrs:{"span":24}},[_c('a-skeleton',{attrs:{"active":""}}),_c('a-skeleton',{attrs:{"active":""}})],1),_c('a-col',{attrs:{"span":12}},[_c('a-skeleton',{attrs:{"active":""}}),_c('a-skeleton',{attrs:{"active":""}})],1),_c('a-col',{attrs:{"span":12}},[_c('a-skeleton',{attrs:{"active":""}}),_c('a-skeleton',{attrs:{"active":""}})],1),_c('a-col',{attrs:{"span":24}},[_c('a-skeleton',{attrs:{"active":""}}),_c('a-skeleton',{attrs:{"active":""}}),_c('a-skeleton',{attrs:{"active":""}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }